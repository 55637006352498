import React, { useMemo, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { CLOUD_FRONT_URL, US_CLOUD_FRONT_URL } from "../../../aws.config";
import { Calculator } from "../../../components/Calculator";
import { addToProjectAtom, addToProjectImageAtom, currentCustomizationAtom, fabricAtom, preFixAtom } from "../../../state";
import AccessLinkPage from "./AccessLinkPage";
import { ADD_PROJECT_SUMMARY } from "../graphQl/Mutation";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { Spin } from "antd";
import moment from "moment";
import { fabricCompanyId } from "../../../lib/constant";

const OrderSummery = () => {
    const [modalShow, setModalShow] = useState(false);
    const [projectSaveLoading, setProjectSaveLoading] = useState(false);
    const [addToProject, setAddToProject] = useRecoilState(addToProjectAtom);
    const [uniqueCode, setUniqueCode] = useState("");
    const [productSummary, setProductSummary] = useState([]);
    const preFix = useRecoilValue(preFixAtom);
    const [addToProjectImage, setAddToProjectImage] = useRecoilState(addToProjectImageAtom);
    const [currentCustomization, setCurrentCustomization] = useRecoilState(currentCustomizationAtom);
    const [fabric, setFabricsAtomState] = useRecoilState(fabricAtom);
    const totalPrice = useMemo(() => addToProject.reduce((acc, d) => acc + (d.sumCalculate || 0), 0), [addToProject]);

    const userDataJSON = localStorage.getItem('userData');
    const userData = userDataJSON ? JSON.parse(userDataJSON) : null;

    const [addProjectMutation] = useMutation(ADD_PROJECT_SUMMARY)

    // Add and remove quantity handler
    const quentityHandler = (type, index, quen) => {
        if (quen) {
            const updateData = addToProject?.map((data, i) => {
                if (i === index) {
                    const update = data?.parts?.map(part => part?.type === type ? { ...part, quantity: quen } : part)
                    const newData = { ...data, parts: update };
                    return newData
                } else {
                    return data
                }
            })
            setAddToProject(updateData);
        }
    }

    const pillowQuentityHandler = (index, quen) => {
        if (quen) {
            const updateData = addToProject?.map((data, i) => {
                const sumCalculate = Calculator(data?.parts, quen)
                return i === index ? { ...data, quantity: quen, sumCalculate, parts: data?.parts } : data
            })
            setAddToProject(updateData);
        }
    }

    // remove part handler
    const removeItem = (index, id) => {
        let indexRemove = null
        const updateData = addToProject.map((data, i) => {
            if (i === index) {
                const update = data?.parts?.filter((part, partIndex) => partIndex !== id);
                if (update?.length) {
                    return { ...data, parts: update }
                } else {
                    indexRemove = i
                    return null
                }
            }
            return data;
        }).filter(Boolean);
        if (indexRemove !== null) {
            const updateImage = addToProjectImage.filter((data, i) => indexRemove !== i)
            setAddToProjectImage(updateImage);
            indexRemove = null
        }
        setAddToProject(updateData);
    }

    // remove product
    const removeProduct = (index) => {
        const updateData = addToProject.filter((data, i) => index !== i)
        const updateImage = addToProjectImage.filter((data, i) => index !== i)
        setAddToProject(updateData);
        setAddToProjectImage(updateImage);
    }

    // render add project item
    const renderItem = (summary, index) => {
        return <div key={`${summary?.name} ${index}`}>
            <div className="container text-center p-0">
                <div className="container p-0">
                    <div className="cart border-bottom">
                        <div className="row">
                            <div className="col-12 col-sm-4 col-md-6 col-lg-4">
                                <div className="cart-item d-flex align-items-center justify-content-start">
                                    <div style={{ width: '70%' }}>
                                        <img src={`${preFix === "us" ? US_CLOUD_FRONT_URL : CLOUD_FRONT_URL}${summary?.base64Image}`} className="img-fluid" alt="badge-img" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-md-4 col-lg-6 d-flex text-start py-4">
                                <div>
                                    <p className="m-0">Name: {summary?.name}</p>
                                    <p className="m-0 color-gray">Paint: {summary?.fabric?.paint?.name}</p>
                                    <p className="m-0 color-gray">Finish: {summary?.alphaSelect?.name}</p>
                                    <p className="m-0 color-gray">Hardware: {summary?.fabric?.handle || 'Plain Fabric'}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row border-bottom pb-4 ">
                {summary?.parts?.map((data, i) => {
                    return <div className="col-lg-4 col-md-6 col-sm-12 mt-sm-3 pt-4 d-flex" key={`${data?.name} ${i}`}>
                        <div className="vintage-summary-part">
                            <div className="cart-item d-flex align-items-center justify-content-start">
                                <div className="cart-item-img box-shadow-none">
                                    <img src={data?.img} className="img-fluid" alt="badge-img" />
                                </div>
                            </div>
                            <div className="quantity-box">
                                <div className="cursor-pointer width-33" onClick={() => quentityHandler(data?.type, index, data?.quantity - 1)}>
                                    <span>-</span>
                                </div>
                                <div className="width-33">{data?.quantity}</div>
                                <div className="cursor-pointer width-33" onClick={() => quentityHandler(data?.type, index, data?.quantity + 1)}>
                                    <span>+</span>
                                </div>
                            </div>
                        </div>
                        <div className="vintage-summary-second-part">
                            <div className="ms-3">
                                <p className="d-flex text-start ">{data?.name}</p>
                                <p className="color-gray d-flex cursor-pointer" onClick={() => removeItem(index, i)}>
                                    Remove X
                                </p>
                            </div>
                        </div>
                    </div>
                })}
            </div>
        </div>
    }

    const pillowRender = (summary, index) => {
        const { name: pillowName, parts: summaryPart } = summary;
        const findPart = (type) => summaryPart?.find(data => data?.type === type);
        const pillowFront = findPart('Front');
        const pillowBack = findPart('Back');
        const pillowSize = findPart('size')?.name || '';
        const pillowEdge = findPart('edge') || findPart('border');
        const pillowBand = findPart('band');
        const insertType = findPart('insertType');

        return <div className="container text-center p-0">
            <div className="container p-0">
                <div className="cart py-4 border-bottom"
                    key={`${summary?.name} ${index}`}
                >
                    <div className="row">
                        <div className="col-12 col-sm-12 col-lg-4" style={{ marginBottom: 10 }}>
                            <div className="cart-item d-flex align-items-center justify-content-start">
                                <div>
                                    <div className="cart-item-img d-flex align-center box-shadow-none" style={{ marginRight: 10 }}>
                                        {pillowFront?.image ?
                                            <img src={pillowFront?.image} className="img-fluid" alt="badge-img" />
                                            :
                                            <img src={'defalutImage.jpg'} className="img-fluid" alt="badge-img" />
                                        }
                                    </div>
                                    <p className="m-0">Front</p>
                                </div>
                                <div>
                                    <div className="cart-item-img d-flex align-center box-shadow-none">
                                        {pillowBack?.image ?
                                            <img src={pillowBack?.image} className="img-fluid" alt="badge-img" />
                                            :
                                            <img src={'defalutImage.jpg'} className="img-fluid" alt="badge-img" />
                                        }
                                    </div>
                                    <p className="m-0">Back</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-5 d-flex text-start">
                            <div>
                                <p className="m-0">Name: {pillowName}</p>
                                <p className="m-0 color-gray">Size: {pillowSize}</p>
                                {pillowFront?.name && <p className="m-0 color-gray">Front: {pillowFront?.name}</p>}
                                {pillowBack?.name && <p className="m-0 color-gray">Back: {pillowBack?.name}</p>}
                                {pillowEdge && <p className="m-0 color-gray">{pillowEdge?.name}: {pillowEdge?.finishName}</p>}
                                {pillowBand && <p className="m-0 color-gray">{pillowBand?.name}: {pillowBand?.finishName}</p>}
                                {insertType && <p className="m-0 color-gray">Insert: {insertType?.name}</p>}
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-3 d-flex justify-content-between">
                            <div className="qty-rmv ">
                                <div className="quantity " style={{ userSelect: 'none' }}>
                                    <p className="quantity__minus cursor-pointer"
                                        onClick={() => pillowQuentityHandler(index, summary?.quantity - 1)}
                                    >
                                        <span>-</span>
                                    </p>
                                    <input
                                        name="quantity"
                                        type="text"
                                        className="quantity__input"
                                        value={summary?.quantity}
                                        onChange={(e) => pillowQuentityHandler(index, Number(e.target.value))}
                                    />
                                    <p className="quantity__plus cursor-pointer"
                                        onClick={() => pillowQuentityHandler(index, summary?.quantity + 1)}
                                    >
                                        <span>+</span>
                                    </p>
                                </div>
                            </div>
                            <div className="price text-right" style={{ marginTop: 10 }}>
                                <p className="multi-qty-price price-text mb-0">${summary?.sumCalculate?.toFixed(2)}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }

    const upholsteryRender = (summary, index) => {
        const { armStyle, seatCushion, backPillow, baseOrLeg, nailhead } = summary?.upholsteryItem
        const { Base, Legs } = summary?.paintName

        return <div className="container text-center p-0">
            <div className="container p-0">
                <div className="cart pb-4 border-bottom"
                    key={`${summary?.name} ${index}`}
                >
                    <div className="row">
                        <div className="col-12 col-sm-4 col-md-6 col-lg-4">
                            <div className="cart-item d-flex align-items-center justify-content-start">
                                <div>
                                    <img src={`${preFix === "us" ? US_CLOUD_FRONT_URL : CLOUD_FRONT_URL}${summary?.base64Image}`} className="img-fluid" alt="badge-img" />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-4 col-lg-6 d-flex text-start pt-4">
                            <div>
                                <p className="m-0">Name: {summary?.name}</p>
                                {Base && <p className="m-0 color-gray">Fabric: {Base}</p>}
                                <p className="m-0 color-gray">Arm Style: {armStyle?.name}</p>
                                <p className="m-0 color-gray">Seat Cushion: {seatCushion?.name}</p>
                                <p className="m-0 color-gray">Back Pillow: {backPillow?.name}</p>
                                <p className="m-0 color-gray">Base Or Leg: {baseOrLeg?.name}</p>
                                {Legs && <p className="m-0 color-gray">Base Or Leg Wood: {Legs}</p>}
                                <p className="m-0 color-gray">Nailhead: {nailhead?.name}</p>

                            </div>
                        </div>
                        <div className="col-12 col-sm-2 col-md-2 col-lg-2 pt-4 d-flex justify-content-end">
                            <div className="qty-rmv ">
                                <div className="quantity " style={{ userSelect: 'none' }}>
                                    <p className="quantity__minus cursor-pointer"
                                        onClick={() => pillowQuentityHandler(index, summary?.quantity - 1)}
                                    >
                                        <span>-</span>
                                    </p>
                                    <input
                                        name="quantity"
                                        type="text"
                                        className="quantity__input"
                                        value={summary?.quantity}
                                        onChange={(e) => pillowQuentityHandler(index, Number(e.target.value))}
                                    />
                                    <p className="quantity__plus cursor-pointer"
                                        onClick={() => pillowQuentityHandler(index, summary?.quantity + 1)}
                                    >
                                        <span>+</span>
                                    </p>
                                </div>
                                <p className="remove-text d-flex align-items-center cursor-pointer"
                                    onClick={() => removeProduct(index)}
                                >
                                    Remove X
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }

    const createAndApplyTexture = (channel, modelPartName) => {
        const modelViewer = document.querySelector("#model-viewer")
        const model = modelViewer?.model;
        if (model) {
            const parts = model[Object.getOwnPropertySymbols(model)[1]];
            const meterialIndexies = [];
            const partWiseIndex = {};
            parts.forEach((part) => {
                const index = part.initialMaterialIdx;
                partWiseIndex[index] = index;
                if (modelPartName === part.name) {
                    meterialIndexies.push(index);
                }

            });

            meterialIndexies?.forEach((index) => {
                const material = model?.materials[index];
                if (channel.includes('base') || channel.includes('metallic')) {
                    material.pbrMetallicRoughness[channel].setTexture(null);
                } else {
                    material[channel].setTexture(null);
                }
            });
        }
    }

    const updateMaterial = async () => {
        let keys, material;

        keys = Object.keys(fabric.partWiseFabric);
        material = fabric;
        if (keys.length === 0) {
            return;
        }
        for (const key of keys) {
            if (material.partWiseFabric[key]) {
                await createAndApplyTexture("baseColorTexture", key);
                await createAndApplyTexture("metallicRoughnessTexture", key);
                await createAndApplyTexture("normalTexture", key);
            }
        }
    };

    const handleSaveProject = () => {

        const newData = addToProject?.map(data => {
            if (data?.type === 'pillow') {
                const newPart = data?.parts?.map(partData => {
                    const { image, ...rest } = partData
                    return { ...rest }
                })
                return { ...data, parts: newPart }
            } else {
                return data
            }
        })
        let sortedProductList = [...newData]
        sortedProductList.sort((a, b) => {
            const dateA = new Date(a.createdAt);
            const dateB = new Date(b.createdAt);
            return dateB.getTime() - dateA.getTime();
        });
        let keyArray = []
        sortedProductList?.map(data => {
            const newId = data?.parts?.map(data1 => data1?.vendor)
            keyArray = [...keyArray, ...newId]
        })

        const finalArray = keyArray?.filter(Boolean)
        const hostName = window.location.host === 'design.suitepieces.com'
        const input = {
            name: userData?.name,
            email: userData?.email,
            projectPending: moment().format('YYYY-MM-DD'),
            productSummary: sortedProductList,
            environment: hostName,
            // environment: false,
            companyId: fabricCompanyId,
            customerId: userData?._id,
            totalPrice: totalPrice,
            fabricKeys: [...new Set(finalArray)]
        }
        setProjectSaveLoading(true)
        addProjectMutation({ variables: { input: input } })
            .then(({ data }) => {
                setUniqueCode(data?.addProductSummary?.uniqueCode)
                setProductSummary(data?.addProductSummary)
                toast.success(Notification['Save project'])
                setProjectSaveLoading(false)
                updateMaterial()
                setFabricsAtomState({
                    currentPart: [],
                    partWiseFabric: {},
                })
                setCurrentCustomization({
                    color: "",
                    pattern: "",
                    size: {
                        name: "12x20",
                        image: "12x20.png",
                        setSize: '12x20'
                    },
                    quantity: 1,
                    border: {
                        name: 'No',
                        image: "pillows-no.png",
                    },
                    band: {
                        name: 'No',
                        image: "pillows-no.png",
                        type: 'No'
                    },
                    insertType: {
                        name: 'Pillow Cover Only',
                        image: 'Pillow Cover Only.png'
                    }
                })
                setModalShow(true);
                setAddToProject([])
            }).catch(err => {
                toast.error(err?.message || err)
                setProjectSaveLoading(false)
            })
    }
    return (
        <>

            {addToProject?.length ?
                <div className="container text-center">
                    <div className="container">
                        <h2 className="title">Pricing Summary</h2>
                        <h5 className="sub-title mb-5">
                            You will need these products for this project
                        </h5>
                        {addToProject?.map((summary, index) => {
                            return <div key={`${summary?.name} ${index}`} className='mt-3'>
                                <div className=" d-flex justify-content-between fw-400 ">
                                    <div className="fs-20">
                                        {summary?.seriesName} {summary?.name}
                                    </div>
                                    <div className="fs-16 text-red d-flex align-items-center cursor-pointer m-0" onClick={() => removeProduct(index)}>
                                        Remove
                                    </div>
                                </div>
                                <div className="border-gray mt-3"></div>
                                {summary?.type === 'vintage' ? renderItem(summary, index) : summary?.type === 'upholstery' ? upholsteryRender(summary, index) : pillowRender(summary, index)}
                            </div>
                        })}
                        <div className="d-flex align-items-center justify-content-md-end justify-content-center btn-mutiple flex-wrap">
                            <button
                                className="btn btn-outline-primary mb-3"
                                onClick={handleSaveProject}
                                disabled={projectSaveLoading}
                            >
                                Save Project {projectSaveLoading && <Spin className="project-save-submit-loader" />}
                            </button>
                        </div>
                    </div>
                </div>
                : <></>
            }

            {/* {modalShow?.modalOne && <SaveProjectModal
                modalShow={modalShow}
                setModalShow={setModalShow}
                setUniqueCode={setUniqueCode}
                setProductSummary={setProductSummary}
            />
            } */}
            {modalShow && <AccessLinkPage
                setModalShow={setModalShow}
                setUniqueCode={setUniqueCode}
                productSummary={productSummary}
                uniqueCode={uniqueCode}
            />
            }

        </>
    )

}

export default OrderSummery;